import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import DemoVideo from "../components/demo_video"
import TestimonialsAgents from "../components/testimonials_agents"
import MailchimpFlowFindingsForm from "../components/mailchimp_flow_findings_form"
import Footer from "../components/footer"

import cover from "../images/partner_cover.png"
import wave from "../images/wave.svg"
import cta_box from "../images/cta_box.svg"

import partner_phone_listings from "../images/partner_phone_listings.png"
import partner_audience from "../images/partner_audience.png"
import partner_facebook_circle from "../images/partner_facebook_circle.png"
import boodle_desktop from "../images/boodle_desktop.png"
import partners_facebook_ads from "../images/partners_facebook_ads.png"
import partners_listings_desktop from "../images/partners_listings_desktop.png"
import partners_sms_email from "../images/partners_sms_email.png"
import teljoy_casestudy from "../images/teljoy_casestudy.png"

import betterbond from "../images/logos/partners/betterbond.png"
import boodle from "../images/logos/partners/boodle.png"
import budget from "../images/logos/partners/budget.png"
import easyfix from "../images/logos/partners/easyfix.png"
import teljoy from "../images/logos/partners/teljoy.png"
import outsurance from "../images/logos/partners/outsurance.png"
import kingprice from "../images/logos/partners/kingprice.png"
import xpello from "../images/logos/partners/xpello.png"

const FlowFindings = () => (
  <Layout>
    <SEO
      title="Get your brand in front of Property Buyers and Renters"
      description="Join the Flow Partner Marketplace and grow your brand through
                relevant and personalised placements in front of property buyers
                and sellers"
      image={cover}
    />
    <Navigation />

    <section className="w-full md:px-10 mt-12">
      <div className="container mx-auto py-5">
        <header
          className="flex flex-col-reverse md:flex-row w-full top-0 max-w-5xl mx-auto"
          style={{ marginTop: "6vh" }}
        >
          <div className="w-full md:w-1/2 mt-12 md:mt-0">
            <div className="text-center md:text-left">
              <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight max-w-md mx-auto md:mx-0">
                Get your brand in front of{" "}
                <span className="wrap">Property</span>{" "}
                <span className="wrap">Buyers</span> and{" "}
                <span className="wrap">Renters</span>
              </h1>

              <h3 className="text-md md:text-xl text-gray-700 animated fadeIn max-w-md mx-auto md:mx-0 mt-4">
                Join the Flow Partner Marketplace and grow your brand through
                relevant and personalised placements in front of property buyers
                and sellers
              </h3>

              <Link
                to="/book-demo-partners"
                className="inline-block btn-primary mt-6 animated fadeIn"
              >
                <i className="fas fa-file text-sm mr-2"></i>Book a demo
              </Link>
            </div>
          </div>

          <div className="w-full md:w-1/2 px-5 md:p-0">
            <img src={cover} alt="" className="w-full mx-auto" />
          </div>
        </header>
      </div>
    </section>

    <img className="w-full" src={wave} alt="" />

    <section className="container mx-auto px-10 md:px-0">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-3xl max-w-2xl mx-auto font-bold text-gray-700 leading-tight">
          Get access to property buyers and renters at scale through Flow
          audiences and Facebook
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-xl mx-auto mt-3">
          Reach buyers on multiple placements during their home buying or
          renting journey
        </p>
      </div>

      <div className="max-w-4xl mx-auto flex flex-col md:flex-row justify-between text-center mt-10">
        <div>
          <img
            src={partner_phone_listings}
            alt=""
            className="w-2/3 mt-10 mx-auto"
          />
          <h2 className="text-2xl md:text-2xl font-bold text-gray-700 leading-tight">
            100’x of 1000’s
          </h2>
          <p className="text-sm font-light text-gray-400">
            of Flow Buyers and Renters
          </p>
        </div>
        <div>
          <img src={partner_audience} alt="" className="w-2/3 mt-10 mx-auto" />
          <h2 className="text-2xl md:text-2xl font-bold text-gray-700 leading-tight">
            Lookalike Audience
          </h2>
          <p className="text-sm font-light text-gray-400">
            from Flow Buyers and Renter Data
          </p>
        </div>
        <div>
          <img
            src={partner_facebook_circle}
            alt=""
            className="w-2/3 mt-10 mx-auto"
          />
          <h2 className="text-2xl md:text-2xl font-bold text-gray-700 leading-tight">
            Reach 23m
          </h2>
          <p className="text-sm font-light text-gray-400">on Facebook</p>
        </div>
      </div>
    </section>

    <div className="w-full mt-10 md:my-32 px-10 md:px-0">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-3xl max-w-2xl mx-auto font-bold text-gray-700 leading-tight">
          Some of our Marketplace Partner clients
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-lg mx-auto mt-3">
          Who are creating brand lift, driving leads and sales of their products
        </p>
      </div>

      <div className="container mx-auto flex flex-wrap mt-4">

        <div className="w-1/2 md:w-1/4 p-2">
          <div className="bg-gray-100 rounded-lg h-12 md:h-20 w-full flex">
            <img
              className="h-full w-auto mx-auto opacity-75"
              src={budget}
              alt=""
            />
          </div>
        </div>

        <div className="w-1/2 md:w-1/4 p-2">
          <div className="bg-gray-100 rounded-lg h-12 md:h-20 w-full flex">
            <img
              className="h-full w-auto mx-auto opacity-75"
              src={betterbond}
              alt=""
            />
          </div>
        </div>

        <div className="w-1/2 md:w-1/4 p-2">
          <div className="bg-gray-100 rounded-lg h-12 md:h-20 w-full flex">
            <img
              className="h-full w-auto mx-auto opacity-75"
              src={teljoy}
              alt=""
            />
          </div>
        </div>

        <div className="w-1/2 md:w-1/4 p-2">
          <div className="bg-gray-100 rounded-lg h-12 md:h-20 w-full flex">
            <img
              className="h-full w-auto mx-auto opacity-75"
              src={outsurance}
              alt=""
            />
          </div>
        </div>

        <div className="w-1/2 md:w-1/4 p-2">
          <div className="bg-gray-100 rounded-lg h-12 md:h-20 w-full flex">
            <img
              className="h-full w-auto mx-auto opacity-75"
              src={easyfix}
              alt=""
            />
          </div>
        </div>

        <div className="w-1/2 md:w-1/4 p-2">
          <div className="bg-gray-100 rounded-lg h-12 md:h-20 w-full flex">
            <img
              className="h-full w-auto mx-auto opacity-75"
              src={kingprice}
              alt=""
            />
          </div>
        </div>

        <div className="w-1/2 md:w-1/4 p-2">
          <div className="bg-gray-100 rounded-lg h-12 md:h-20 w-full flex">
            <img
              className="h-full w-auto mx-auto opacity-75"
              src={boodle}
              alt=""
            />
          </div>
        </div>

        <div className="w-1/2 md:w-1/4 p-2">
          <div className="bg-gray-100 rounded-lg h-12 md:h-20 w-full flex">
            <img
              className="h-full w-auto mx-auto opacity-75"
              src={xpello}
              alt=""
            />
          </div>
        </div>

      </div>
    </div>

    <img className="w-full mt-10" src={wave} alt="" />

    <section className="py-10 md:py-32 px-5 mx-5 md:mx-10 rounded-xl  relative">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl max-w-2xl mx-auto font-bold text-gray-700 leading-tight">
          Flow Partner Marketplace
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-xl mx-auto mt-3">
          Get a dedicated brand page on the Flow Partner Portal
        </p>
      </div>

      <div className="container mx-auto flex flex-col md:flex-row mt-24">
        <div className="flex w-full md:w-1/2 h-100 md:pr-10">
          <img className="mx-auto self-center" src={boodle_desktop} alt="" />
        </div>
        <div className="w-full md:w-1/2 text-center md:text-left mt-10 md:mt-0">
          <div className="max-w-sm">
            <div className="w-full flex mt-12">
              <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
                <i className="w-full self-center fas fa-file-alt"></i>
              </div>
              <div className="self-center">
                <h3 className="font-medium text-gray-600 text-left">
                  Forms to API link directly to your call center or CRM
                </h3>
              </div>
            </div>

            <div className="w-full flex mt-5">
              <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
                <i className="w-full self-center fas fa-cogs"></i>
              </div>
              <div className="self-center">
                <h3 className="font-medium text-gray-600 text-left">
                  Show case your features and benefits
                </h3>
              </div>
            </div>

            <div className="w-full flex mt-5">
              <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
                <i className="w-full self-center fas fa-home"></i>
              </div>
              <div className="self-center">
                <h3 className="font-medium text-gray-600 text-left">
                  Featured as recommended partner on property listings
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-gray-100 mt-12 md:mt-12 py-10 md:py-32 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl max-w-2xl mx-auto font-bold text-gray-700 leading-tight">
          How it Works
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-xl mx-auto mt-3">
          A multi-channel approach is used to target Flow’s audience of people
          looking to buy or rent property, and drive awareness and sales of
          Partner products and services
        </p>
      </div>

      <div className="container mx-auto flex flex-col-reverse md:flex-row-reverse mt-24">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-2xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Facebook/Instagram Retargeting
          </h2>
          <p className="text-md md:text-lg text-center md:text-left font-light text-gray-400 max-w-xs mt-4">
            Your offer will be shown in an ad, targeted to Flow users
          </p>

          <div className="w-full md:w-2/3 text-left flex mt-12">
            <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
              <i className="w-full self-center text-xs fas fa-user-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Retargeting existing Flow users, buyers and renters who are in
                the market to purchase or rent
              </p>
            </div>
          </div>

          <div className="w-full md:w-2/3 text-left flex mt-3">
            <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
              <i className="w-full self-center text-xs fas fa-handshake"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Prospective buyer targeting through lookalike audiences
              </p>
            </div>
          </div>

          <div className="w-full md:w-2/3 text-left flex mt-3">
            <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
              <i className="w-full self-center text-xs fas fa-tools"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Combination of lead ads (API to call center) and conversion ads
                to landing page
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 md:pl-12">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pr-20"
            src={partners_facebook_ads}
            alt=""
          />
        </div>
      </div>

      <div className="container mx-auto flex flex-col-reverse md:flex-row-reverse mt-24">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-2xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            1000’s of Enquiries
          </h2>
          <p className="text-md md:text-lg text-center md:text-left font-light text-gray-400 max-w-xs mt-4">
            Flow property listings get 1000’s of new enquiries every month
          </p>

          <div className="w-full md:w-2/3 text-left flex mt-12">
            <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
              <i className="w-full self-center text-xs fas fa-home"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Position your offer in front of leads exactly when they are
                actively looking at properties to move in to
              </p>
            </div>
          </div>

          <div className="w-full md:w-2/3 text-left flex mt-3">
            <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
              <i className="w-full self-center text-xs fas fa-envelope"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Placement on enquiry confirmation SMS and emails
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 md:pl-12">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pr-20"
            src={partners_sms_email}
            alt=""
          />
        </div>
      </div>

      <div className="container mx-auto flex flex-col-reverse md:flex-row-reverse mt-24">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-2xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Flow Property Listings
          </h2>
          <p className="text-md md:text-lg text-center md:text-left font-light text-gray-400 max-w-xs mt-4">
            Your value proposition will be featured on property listings on the
            Flow platform
          </p>

          <div className="w-full md:w-2/3 text-left flex mt-12">
            <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
              <i className="w-full self-center text-xs fas fa-user-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Target Flow users who are actively looking at properties to
                purchase or rent
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 md:pl-12">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pr-20"
            src={partners_listings_desktop}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="py-10 md:py-32 px-5 mx-5 md:mx-10 rounded-xl  relative">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl max-w-2xl mx-auto font-bold text-gray-700 leading-tight">
          Case Studies
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-xl mx-auto mt-3">
          See how businesses our agency, developer and brand partner clients are
          growing their business with Flow
        </p>
      </div>

      <img className="w-full mt-10 rounded-xl" src={teljoy_casestudy} alt="" />

      <div className="container mx-auto flex flex-col md:flex-row justify-between md:mt-24">
        <div className="w-full md:w-1/2 text-center md:text-left mt-10 md:mt-0">
          <h2 className="text-xl md:text-3xl max-w-2xl mx-auto font-bold text-gray-700 leading-tight">
            Growing High Quality Traffic to the Teljoy E-commerce Site
          </h2>
          <p className="inline-block text-md font-light text-gray-400 max-w-xl mx-auto mt-3">
            A well established and trusted household name, Teljoy is a brand
            with over 51 years of history in providing South Africans with
            quality household products within a budget that they can afford. As
            a brand that is always striving to go the extra mile and evolve with
            the times, Teljoy recently pivoted to a rent-to-own model, offering
            a wide range of household products with the option to take ownership
            after the rental period.
            <br />
            <br /> To facilitate growth, Teljoy partnered with Flow with the
            objective to find new sources of high quality leads, and to drive
            awareness and higher volumes of traffic to their e-commerce site,
            resulting in an increase in transactions.
          </p>
        </div>
        <div className="w-full md:w-1/3 text-center md:text-left mt-10 md:mt-0">
          <div>
            <h3 class="font-bold text-5xl text-orange-500">3x</h3>
            <p class="font-light text-xs md:text-sm text-gray-400 mr-4">
              Session time on the Less cost per Increased overall volume Teljoy
              website
            </p>
          </div>
          <div>
            <h3 class="font-bold text-5xl text-orange-500">20%</h3>
            <p class="font-light text-xs md:text-sm text-gray-400 mr-4">
              Less cost per Increased overall volume Teljoy website purchase
            </p>
          </div>
          <div>
            <h3 class="font-bold text-5xl text-orange-500">+</h3>
            <p class="font-light text-xs md:text-sm text-gray-400 mr-4">
              Increased overall volume Teljoy website purchase and monthly
              growth
            </p>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-10">
        <Link to="/case-studies" className="text-orange-500 underline">
          See more case studies
        </Link>
      </div>
    </section>

    <img className="w-full" src={wave} alt="" />

    <section className="w-full px-4 mt-10 md:mt-24">
      <div className="container mx-auto flex justify-between bg-bluelight-100 overflow-hidden rounded-lg flex-col md:flex-row text-center md:text-left">
        <div className="p-10">
          <h2 className="text-xl md:text-3xl text-gray-700 font-bold leading-snug max-w-lg">
            Want to see how Flow can help <span className="wrap-sm">grow</span>{" "}
            your brand?
          </h2>
          <p className="text-bluelight-600 mt-4">Book a demo today.</p>

          <Link
            to="/book-demo-partners"
            className="inline-block btn-primary text-center mt-10"
          >
            Book a demo
          </Link>
        </div>
        <div className="hidden md:flex">
          <img
            className="self-end mr-12 bottom-0"
            style={{ height: "220px" }}
            src={cta_box}
            alt=""
          />
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
)

export default FlowFindings
